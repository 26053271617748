<template>
  <div
    class="row position-relative w-100 m-0 p-0"
    style="z-index: 100"
    :style="getPageDesign()">
    <div
      class="bg lazyload"
      :data-bg="MainImgSrc(getImageSrc(pageDesign.imageBlock , isDesktopView))"
      v-if="pageDesign.pageStyles.imageAlign == 'no'"
      :key="getImageSrc(pageDesign.imageBlock , isDesktopView)"
:style="
getbackgroundImage(
IsImageAdded,
pageDesign.imageBlock,
pageDesign.pageStyles.imageAlign , isDesktopView
) + getBgDivStyles()
"
      ></div>
    <!-- BackGroundDiv end-->
    <div v-if="showAlert" class="AlertContainer">
      <div
        class="alert text-center fade show text-white alert-text"
        role="alert"
        style="background: #f27367; width: fit-content">
        <span>
          {{ alertMsg }}
        </span>
      </div>
    </div>
    <!-- Image Align Left -->
    <div
      class="px-0"
      v-show="showLeftImage"
      :class="[
        {
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division col-6': isDesktopView,
        },
      ]"
      :style="
        calculateImageWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive
        )
      ">
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView)">
          <LazyImage
            :style="
              getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style,
                IsImageVariableWidthActive,
                pageDesign.imageBlock
              ) +
              getImageRotation(pageDesign.imageBlock.style) +
              getImageOpacity(pageDesign.imageBlock.style)
            "
            :class="[
              `pic-BaldenStep1`,
              getImageSrc(pageDesign.imageBlock , isDesktopView) == '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ]"
            :src="MainImgSrc(getImageSrc(pageDesign.imageBlock , isDesktopView))"
            alt />
        </div>
      </div>
    </div>

    <!-- Main Section -->
    <div
      :class="[!IsImageAdded ? 'col-12 pr-0' : imageAddedLayout]"
      :style="
        getMainPaddingCss(pageDesign.pageStyles, isDesktopView) +
        getScrollCSS(
          pageDesign.pageStyles,
          isDesktopView,
          pageDesign.imageBlock.style,
          pageDesign.pageType
        ) + 'height:630px; overflow:scroll'+
        calculateContentWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive,
          pageDesign.pageStyles
        )
      ">
      <div class="px-3"
        style="
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          height: 100%;
        ">
        <div style="overflow: hidden scroll" class="h-100">
          <div
            v-for="(pro, ind) in pageDesign.blocksArray"
            :key="'preview' + ind"
            class="">
            <div
              id="preview"
              :style="getHeightWidth(pro.style)"
              class="content"
              style="position: relative">
              <div
                v-if="pro.type == 'text'"
                :style="
                  getColorCss(pro.style) +
                  getPaddingCss(pro.style) +
                  getMarginCss(pro.style, isDesktopView) +
                  getColorWithOpacity(pro.style) +
                  getTextRotate(pro.style) +
                  getLineHeight(pro.style) +
                  getTextAlign(pro.style) +
                  getFontStyle(pro.style,isDesktopView)
                "
                v-html="getTextValue(translateCustomText( pro.content))"></div>

              <!-- Free Text Input -->

              <div v-if="pro.type == 'freeText'" class="px-2 px-md-0">
                <div
                  :style="
                    getMarginCss(pro.style, isDesktopView) +
                    getTextAlign(pro.style)
                  ">
                  <div class="mb-1" v-if="pro.showLabel">
                    <p
                      class="m-0 p-0 text-left font-weight-bold"
                     >
                      <!-- :style="leadLabelStyle(pro.style)" -->
                      {{ translateCustomText( pro.labelText) }}
                      <span v-if="pro.isRequired" style="color: red">*</span>
                    </p>
                  </div>
                  <div
                    :style="
                      pro.style.borderType === 'border'
                        ? getInputBorderCSS(pro.style)
                        : getInputBorderBottomCSS(pro.style)
                    ">
                    <input
                      v-if="
                        pro.selectedInfoType.fieldName !== 'date' &&
                        pro.selectedInfoType.fieldName !== 'phone_number'
                      "
                      :type="pro.selectedInfoType.type.toLowerCase()"
                      :placeholder="translateCustomText( pro.InfoTypePlaceholder)"
                      @keyup.enter="elthInitiateOtp(pro.selectedInfoType.type)"
                      :required="pro.isRequired"
                      class="text-dark font-weight-medium customBaldenInput CustomTextInput"
                      :style="customInputStyles(pro.style)"
                      v-model="updateTitle" />
                  </div>
                </div>
              </div>

              <div v-if="pro.type == 'freeTextArea'" class="px-2 px-md-0">
                <div
                  :style="
                    getMarginCss(pro.style, isDesktopView) +
                    getTextAlign(pro.style)
                  ">
                  <div
                    class="mb-1 font-weight-bold"
                    v-if="pro.showLabel"
                    >
                    <p class="m-0 p-0 text-left font-weight-bold">
                      {{ translateCustomText(pro.labelText) }}
                      <span v-if="pro.isRequired" style="color: red">*</span>
                    </p>
                  </div>
                  <div
                    class="bg-white p-2"
                    :style="
                      pro.style.borderType === 'border'
                        ? getInputBorderCSS(pro.style)
                        : getInputBorderBottomCSS(pro.style)
                    ">
                  <Editor :productDescription="updateDesc" @updateDescription="updateDescription($event)" :placeholder="translateCustomText(pro.InfoTypePlaceholder)" class="customBaldenTextAreaInput customBaldenInput p-1"></Editor>

                    <!-- <textarea
                      :placeholder="pro.InfoTypePlaceholder"
                      :required="pro.isRequired"
                      v-model="updateDesc"
                      rows="5"
                      :style="freeTextInputStyles(pro.style)"
                      id="baldenTextArea"
                      style="resize: none; overflow: auto"
                      class="customBaldenTextAreaInput p-1 customBaldenInput CustomTextInput" /> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- LEad Ans Back Button -->
        <div>
          <div
            :style="getHeightWidth(StaticButtonElement.style)"
            class="content">
            <div class="w-100">
              <div
                class="d-flex mt-5 justify-content-between"
                
                >
                <button
                  style="z-index: 1000"
                  :style="
                    getCommonButtonCSS(
                      StaticButtonElement.style,
                      isDesktopView
                    ) +
                    getBackNextButtonCss(
                      StaticButtonElement.content.backButton.style,isDesktopView
                    )
                  "
                  @click="$emit('moveTo',1)"
                 >
                  <span
                    :style="{
                      opacity: `${StaticButtonElement.content.backButton.style.textOpacity}%`,
                      fontSize: `${StaticButtonElement.content.backButton.style.fontSize}px`,
                    }"
                    >{{translateCustomText(StaticButtonElement.content.backButton.text) }}</span
                  >
                </button>
                <button
                  style="z-index: 1000"
                  :style="
                    getCommonButtonCSS(
                      StaticButtonElement.style,
                      isDesktopView
                    ) +
                    getBackNextButtonCss(
                      StaticButtonElement.content.nextButton.style,isDesktopView
                    )
                  "
                  @click="nextPage()">
                  <span
                    v-if="!nextBtnSpinner"
                    :style="{
                      opacity: `${StaticButtonElement.content.nextButton.style.textOpacity}%`,
                      fontSize: `${StaticButtonElement.content.nextButton.style.fontSize}px`,
                    }"
                    >{{ translateCustomText(StaticButtonElement.content.nextButton.text) }}</span
                  >
                  <div
                    v-else
                    class="spinner-border spinner-border-sm"
                    role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Image Align Right -->
    <div
      class="px-0"
      v-show="showRightImage"
      :class="[
        {
          'col-12 col-lg-12 col-xs-12 pr-0': !isDesktopView,
          'image-division col-6 pr-0': isDesktopView,
        },
      ]"
      :style="
        calculateImageWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive
        )
      ">
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView) + `height:103%;`">
          <LazyImage
            :style="
              getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style,
                IsImageVariableWidthActive,
                pageDesign.imageBlock
              ) +
              getImageRotation(pageDesign.imageBlock.style) +
              getImageOpacity(pageDesign.imageBlock.style)+'height:650px!important;max-height:650px'
            "
            :class="[
              `pic-BaldenRightImage`,
              getImageSrc(pageDesign.imageBlock , isDesktopView) == '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ]"
            :src="MainImgSrc(getImageSrc(pageDesign.imageBlock , isDesktopView))"
            alt />
        </div>
      </div>
    </div>

    <!-- Show Logo Footer -->

    <!-- End -->
  </div>
</template>

<script>
import axios from "axios";
import cssFunctionMixin from "../../../customize/mixin/cssFunction.js";
import TranslationMixin from "../../../customize/mixin/TranslationMixin";
import { mapGetters } from 'vuex';
export default {
  mixins: [cssFunctionMixin, TranslationMixin],
  props: ["baldenTitle", "baldenDesc", "isDesktopView", "image", "apiToken","pageHeight","quizId"],
  components:{
    Editor: () =>
      import(
        /* webpackChunkName: "Editor" */ "../../../../components/Editor.vue"
      ),
  },
  mounted(){
    this.pageDesign= {
        pageStyles: {
          activeBlockTitle: "Styles",
          activeSubBlock: false,
          paddingTop: "0",
          paddingRight: "0",
          paddingBottom: "0",
          paddingLeft: "0",
          marginLeft: 0,
          marginRight: 0,
          alignItems: "center",
          textAlign: "right",
          backgroundColor: "#F7F0ED",
          color: "black",
          imageAlign: "right",
          onlyBackgroundImg: false,
          imageFor: "both",
          imageSrc: "PAGE_SIDE_IMAGE_PATH",
          bgImageStatus: false,
          bgImageSrc: false,
          fontFamily: "",
          fontSize: "",
          height: "520",
          width: 850,
          jsCode:
            "//Add script without script tag\n//variables --> window.quizell.leadData, window.quizell.allQuestions, \n//window.quizell.selectedQuestionOptions , window.quizell.products.\nconsole.log(window.quizell.getters);",
        },
        blocksArray: [
          {
            type: "text",
            content: `Edit title and description`,
            questionTitle: true,
            style: {
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 10,
              paddingBottom: 10,
              marginTop: "0",
              marginBottom: "0",
              marginLeft: "0",
              marginRight: "0",
              width: "800",
              height: "160",
              textOpacity: 100,
              color: "#503A3C",
              rotate: 0,
              lineHeight: "1.4",
              textDecoration: "unset",
              fontWeight: "bold",
              fontStyle: "normal",
              textAlign: "center",
              fontSize: "22",
              fontFamily: "Open Sans",
            },
            customizeStatus: false,
            hoverStatus: false,
          },
          {
            type: "text",
            content: `*Required fields`,
            questionTitle: false,
            style: {
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 10,
              paddingBottom: 10,
              marginTop: 0,
              marginBottom: 0,
              marginLeft: 0,
              marginRight: 0,
              fontSize: 14,
              width: 700,
              height: 400,
              textOpacity: 100,
              color: "#503A3C",
              rotate: 0,
              lineHeight: "1.5",
              textDecoration: "unset",
              fontWeight: "normal",
              fontStyle: "italic",
              textAlign: "center",
              fontFamily: "Open Sans",
            },
            customizeStatus: false,
            hoverStatus: false,
          },
          {
            type: "freeText",
            labelText: `Title`,
            showLabel: true,
            isRequired: true,
            addInfoTypeEdit: false,
            newInfoType: "",
            infoType: [
              {
                text: "Full Name",
                type: "text",
                fieldName: "full_name",
                fieldValue: "",
              },
              {
                text: "Email",
                type: "email",
                fieldName: "email",
                fieldValue: "",
              },
              {
                text: "Phone Number",
                type: "number",
                fieldName: "phone_number",
                fieldValue: "",
              },
              {
                text: "Date",
                type: "number",
                fieldName: "date",
                dateText: "Date",
                monthText: "Month",
                yearText: "Year",
                fieldValue: "",
                dateValue: "",
                monthValue: "",
                yearValue: "",
              },
              {
                text: "Website",
                type: "text",
                fieldName: "website",
                fieldValue: "",
              },
              {
                text: "Organization",
                type: "text",
                fieldName: "organisation",
                fieldValue: "",
              },
            ],
            InfoTypePlaceholder: `Enter your shop title here`,
            style: {
              marginTop: "0",
              marginBottom: "0",
              marginLeft: "5",
              marginRight: "5",
              borderRadius: "0",
              textOpacity: 100,
              color: "#9C4629",
              textDecoration: "unset",
              fontWeight: "bold",
              fontStyle: "normal",
              textAlign: "left",
              fontSize: 14,
              fontFamily: "Poppins",
              strokeOpacity: 100,
              strokeColor: "#9C4629",
              strokeRotation: 0,
              borderSize: "1",
              borderType: "border",
              DateTextColor: "#000000",
              DateFontFamily: "Poppins",
              DateFontSize: 14,
              DateTextOpacity: 100,
              DateTextDecoration: "unset",
              DateFontWeight: "bold",
              DateFontStyle: "normal",
              DateTextAlign: "left",
            },
            customizeStatus: false,
            hoverStatus: false,
            selectedInfoType: {
              text: "Full Name",
              type: "text",
              fieldName: "full_name",
              fieldValue: "",
            },
          },

          {
            type: "freeTextArea",
            isRequired: true,
            isMaxCharacter: true,
            maxCharacterLength: 400,
            LeadTextValue: "",
            labelText:`Description`,
            showLabel: true,
            InfoTypePlaceholder:`Enter your store description here (maximum 400 characters)`,
            style: {
              marginTop: "20",
              marginBottom: "0",
              marginLeft: "5",
              marginRight: "5",
              borderRadius: "0",
              textOpacity: 100,
              color: "#9C4629",
              textDecoration: "unset",
              fontWeight: "bold",
              fontStyle: "normal",
              textAlign: "left",
              fontSize: 14,
              fontFamily: "Poppins",
              strokeOpacity: 100,
              strokeColor: "#9C4629",
              strokeRotation: 0,
              borderSize: "1",
              borderType: "border",
              DateTextColor: "#000000",
              DateFontFamily: "Poppins",
              DateFontSize: 14,
              DateTextOpacity: 100,
              DateTextDecoration: "unset",
              DateFontWeight: "bold",
              DateFontStyle: "normal",
              DateTextAlign: "left",
            },
            customizeStatus: true,
            hoverStatus: false,
          },

          {
            type: "button",
            content: {
              backButton: {
                text:`← Back`,
                hideBackButton: true,
                style: {
                  backgroundColor: "#ffffff",
                  color: "#9C4629",
                  buttonWidth: "100",
                  buttonHeight: "40",
                  textOpacity: 100,
                  backgroundOpacity: "100",
                  textDecoration: "unset",
                  fontFamily: "Open Sans",
                  fontWeight: "bold",
                  fontStyle: "normal",
                  fontSize: "15",
                  borderRadius: "20",
                  borderSize: "1.5",
                  borderColor: "",
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingTop: 15,
                  paddingBottom: 15,
                  textAlign: "center",
                },
              },
              nextButton: {
                text:`Save`,
                style: {
                  backgroundColor: "#9C4629",
                  color: "#ffffff",
                  buttonWidth: "100",
                  buttonHeight: "40",
                  textOpacity: 100,
                  backgroundOpacity: "100",
                  textDecoration: "unset",
                  fontFamily: "Open Sans",
                  fontWeight: "bold",
                  fontStyle: "normal",
                  fontSize: "15",
                  borderRadius: "20",
                  borderSize: "1.5",
                  borderColor: "",
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingTop: 15,
                  paddingBottom: 15,
                  textAlign: "center",
                },
              },
            },
            style: {
              position: "left",
              marginTop: "0",
              marginBottom: "20",
              marginLeft: "0",
              marginRight: "10",
              lineHeight: 1,
              buttonPosition: "end",
            },
            customizeStatus: false,
            hoverStatus: false,
            staticButton: true,
          },
        ],
        imageBlock: {
          src: this.quizId == this.getEnv.VUE_APP_ITALIAN_QUIZID  ? 'https://cdn.shopify.com/s/files/1/0724/0574/5977/files/Capture_d_ecran_2023-06-02_a_13.26.30.png?v=1685705223' : 'https://cdn.shopify.com/s/files/1/0571/0246/7222/files/Capture_d_ecran_2023-08-24_a_16.49.05.png?v=1692892211',
          customizeStatus: false,
          hoverStatus: false,
          useMobileImage: false,
        useMobileImageSrc:"/images/default_start_page.png",
          style: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
            textAlign: "center",
            backgroundColor: "none",
            width: 300,
            height: 250,
            rotate: 0,
            imageOpacity: "100",
            displayMobile: false,
            displayDesktop: true,
          },
          useImageVariableWidth: false,
          imageVariableWidth: 50,
        },
      },
    console.log('')
  },
  data() {

    return {
      nextBtnSpinner: false,
      showAlert: false,
      alertMsg: "",
      updateTitle: this.baldenTitle,
      updateDesc: this.baldenDesc,
      // Step 1 PageDesign Data
      pageDesign:{}
    };
  },

  computed: {
    ...mapGetters(["getEnvConst"]),
       getEnv(){
      // getEnvConst
      return this.getEnvConst;
    },
    getPageImage(){
      return  this.quizId == (this.getEnv?.VUE_APP_ITALIAN_QUIZID??null) ? 'https://cdn.shopify.com/s/files/1/0724/0574/5977/files/Capture_d_ecran_2023-06-02_a_13.26.30.png?v=1685705223' : 'https://cdn.shopify.com/s/files/1/0571/0246/7222/files/Capture_d_ecran_2023-08-24_a_16.49.05.png?v=1692892211'
    },
    showLeftImage() {
      return (
        (this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayDesktop &&
          this.pageDesign.pageStyles.imageAlign == "left") ||
        (!this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayMobile &&
          (this.pageDesign.pageStyles.imageAlign == "left" ||
            this.pageDesign.pageStyles.imageAlign == "right"))
      );
    },
    showRightImage() {
      return (
        this.isDesktopView &&
        this.pageDesign.imageBlock.style.displayDesktop &&
        this.pageDesign.pageStyles.imageAlign == "right"
      );
    },
    IsImageAdded() {
      if (this.getImageSrc(this.pageDesign.imageBlock , this.isDesktopView) !== "" || this.getUploadedImage)
        return true;
      return false;
    },
    IsImageVariableWidthActive() {
      return this.IsImageAdded && this.pageDesign.pageStyles.imageAlign != "no"
        ? true
        : false;
    },
    imageAddedLayout() {
      if (this.pageDesign.pageStyles.imageAlign == "no") return "col-12";
      if (!this.isDesktopView) return "col-12";
      return "col-12 col-xs-12 col-sm-6 col-md-6  px-3";
    },
    CurrentPage() {
      return "leadPage";
    },
    StaticButtonElement() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "button"
      );
      return this.pageDesign.blocksArray[index];
    },

    getTitleIndex() {
      
      return this.pageDesign.blocksArray.findIndex((x) => x.type == "freeText");
    },
    getDescriptionIndex() {
      return this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "freeTextArea"
      );
    },
    getTitleValue() {
      return this.updateTitle;
    },
    getDescriptionValue() {
      return this.updateDesc;
    },
  },
  watch: {
    getTitleValue(newVal, oldVal) {
      if (newVal.length > 30) {
        this.updateTitle = oldVal;
      }
    },
    getDescriptionValue(newVal, oldVal) {
      if (newVal.length > 400) {
        this.updateDesc = oldVal;
      }
    },
  },
  methods: {
    translateCustomText(key){
        return this.translateToItalian(key,this.quizId)   
      },
     updateDescription(e){
      this.updateDesc=e
    },
    getPageDesign() {
      let design =
        "text-align:" +
        this.pageDesign.pageStyles.textAlign +
        ";color:" +
        this.pageDesign.pageStyles.color +
        "; background:" +
        this.pageDesign.pageStyles.backgroundColor +
        "; width:" +
        this.pageDesign.pageStyles.width +
        "px; height:";

      return design;
    },

    getInputBorderCSS(property) {
      return (
        "border:" +
        property.borderSize +
        "px solid " +
        property.strokeColor +
        "; border-radius:" +
        property.borderRadius +
        "px; " +
        "transform: rotate(-" +
        property.strokeRotation +
        "deg);"
      );
    },
    getInputBorderBottomCSS(property) {
      return (
        "border-bottom:" +
        property.borderSize +
        "px solid " +
        property.strokeColor +
        "; border-radius:" +
        property.borderRadius +
        "px; " +
        "transform: rotate(-" +
        property.strokeRotation +
        "deg);"
      );
    },
    InputAdjust(e) {
      let Element = e.target;
      // Element.rows = 2;
      Element.rows = 1;
      const styles = window.getComputedStyle(Element);
      const paddingTop = parseInt(styles.paddingTop);
      const paddingBottom = parseInt(styles.paddingBottom);
      const padding = (paddingTop + paddingBottom) * 0;
      const initialHeight = (parseInt(styles.height) - padding) / Element.rows;
      const scrollHeight = Element.scrollHeight - padding;
      const newRows = Math.ceil(scrollHeight / initialHeight);
      Element.rows = newRows - 1 == 0 ? 1 : newRows - 1;
    },
    backPage() {
      this.$emit("moveTo",1);
     
    },
    async nextPage() {
      this.nextBtnSpinner = true;
      try {
        let data = {
          title: this.updateTitle,
          description: this.updateDesc,
          email: localStorage.getItem("already_record_email") || this.$route.query.email,
          api_token: this.apiToken,
        };
        if (this.image) data["image"] = this.image;
        const response = await axios.post(
          `/api/custom/collection/update/title`,
          data
        );

        if (response.status == 200) {
           this.$toasted.show(this.translateCustomText(`Updated Title and Description.`), {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          this.$emit("updateTextDesc", {
            title: this.updateTitle,
            desc: this.updateDesc,
          });
           this.$emit("moveTo",1);
        } else {
          this.$toasted.show(this.translateCustomText(`An error has occurred`), {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } catch (error) {
        if (error) {
          this.$toasted.show(this.translateCustomText(`An error has occurred`), {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.nextBtnSpinner = false;
      }
    },
  },
};
</script>

<style scoped>
.CustomTextInput {
  /* color: var(--inputColor) !important; */
  color: #503a3c !important;
}
.customBaldenInput {
  width: 100%;
  background-color: #fff;
  border: none;
  outline: none;
}
.customBaldenInput::placeholder {
  color: var(--inputPlaceholderColor);
  font-size: 14px;
}

#baldenTextArea {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: none;
  outline: none;
  font-weight: normal !important ;
  font-size: 16px !important;
  color: #503a3c !important;
}

#baldenTextArea::placeholder {
  color: var(--inputPlaceholderColor) !important;
  font-weight: normal;
}
</style>
<style>
.el-tiptap-editor__placeholder.el-tiptap-editor--empty:first-child:before, .el-tiptap-editor__with-title-placeholder:first-child:before, .el-tiptap-editor__with-title-placeholder:nth-child(2):before {
     color: #9C4629;
  font-weight: normal;
  font-family:Poppins,'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    content: attr(data-empty-text);
    float: left;
    height: 0;
    pointer-events: none;
}
</style>